import { EntitySchema } from "@camberi/firecms";

let mosqueSchema: EntitySchema = {
    name: "Mosque",
    properties: {
        name: {
            title: "Name",
            validation: { required: true },
            dataType: "string"
        },
        address: {
            title: "Address line 1",
            validation: { required: true },
            dataType: "string"
        },
        city: {
            title: "City",
            validation: { required: true },
            dataType: "string"
        },
        state: {
            title: "State",
            validation: { required: true },
            dataType: "string",
        },
        country: {
            title: "Country",
            validation: { required: true },
            dataType: "string"
        },
        postal: {
            title: "Postal code",
            validation: { required: true },
            dataType: "string"
        },
        lat: {
            title: "Latitude",
            validation: { required: true },
            dataType: "number",
        },
        lon: {
            title: "Longitude",
            validation: { required: true },
            dataType: "number",
        },
        email: {
            title: "Email",
            validation: { email: true },
            dataType: "string",
        },
        phone: {
            title: "Phone",
            dataType: "string",
        },
        website: {
            title: "Website",
            validation: { url: true },
            dataType: "string"
        },
        facebook: {
            title: "Facebook",
            validation: { url: true },
            dataType: "string"
        },
        youtube: {
            title: "Youtube",
            validation: { url: true },
            dataType: "string"
        }
    }
}

export default mosqueSchema;
