// A custom birthday input.
import React from "react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
// import { getIn } from "formik";
import MomentUtils from "@date-io/moment";
import { CMSFieldProps, FieldDescription } from "@camberi/firecms";
import { ReactElement } from "react";
import { Moment } from "moment";

export default function CustomDateTextField({
    property,
    value,
    color,
    touched,
    error,
    isSubmitting,
    setValue,
    ...props
}: CMSFieldProps<String>): ReactElement {
    // const fieldError = getIn(errors, field.name);
    // const showError = getIn(touched, field.name) && !!fieldError;
    const showError = false;

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                value={value ?? ""}
                label={property.title}
                onChange={(newVal: any) => {
                    const md = newVal as Moment;
                    const dateStr = md.format("YYYY-MM-DD");
                    console.log(dateStr);
                    // setFieldTouched(field.name);
                    setValue(
                        dateStr
                    );
                }}
                format="YYYY-MM-DD"
                variant="inline"
                required={property.validation?.required}
                error={showError}
                disabled={isSubmitting}
            ></KeyboardDatePicker>
            <FieldDescription property={property} />
        </MuiPickersUtilsProvider>
    );
}
