import { AdditionalView, Authenticator, buildCollection, CMSApp } from "@camberi/firecms";
import { Button } from "@material-ui/core";
import React from "react";
import {
    announcementSchema,
    businessSchema,
    jobSchema,
    showSchema,
    profileSchema,
    videoSchema
} from "./schemas/schemas";

import logo from "./images/icon-trans.png";
import { firestore, User } from "firebase";
import bannerSchema from "./schemas/BannerSchema";
import marketplaceSchema from "./schemas/MarketplaceSchema";
import { profileSearch } from "./SearchUtils";
import mosqueSchema from "./schemas/MosqueSchema";
import youthGroupSchema from "./schemas/YouthGroupSchema";
import SendNotifications from "./views/SendNotifications";

const firebaseConfig = {
    apiKey: "AIzaSyDGhigfYiz9QH54_oxgCwcFtYvFqaN0Nsw",
    authDomain: "umoja-app.firebaseapp.com",
    databaseURL: "https://umoja-app.firebaseio.com",
    projectId: "umoja-app",
    storageBucket: "umoja-app.appspot.com",
    messagingSenderId: "831522312134",
    appId: "1:831522312134:web:58686cd58a4cfedb11acc7",
    measurementId: "G-M2SF9LD7ZV"
};

const navigation = [
    buildCollection({
        relativePath: "news",
        schema: announcementSchema,
        name: "Announcements",
        deleteEnabled: true,
        excludedProperties: [
            "postWordpress",
            "sendNotification"
        ]
    }),
    buildCollection({
        relativePath: "business_directory",
        schema: businessSchema,
        name: "Business Directory",
        deleteEnabled: true,
    }),
    buildCollection({
        relativePath: "marketplace",
        schema: marketplaceSchema,
        name: "Marketplace",
        deleteEnabled: true,
    }),
    buildCollection({
        relativePath: "mentor-shows",
        schema: showSchema,
        name: "Mentor Shows",
        deleteEnabled: true,
    }),
    buildCollection({
        relativePath: "videos",
        schema: videoSchema,
        name: "Videos"
    }),
    buildCollection({
        relativePath: "profiles",
        schema: profileSchema,
        name: "Profiles",
        textSearchDelegate: profileSearch,
        // customActions: [
        //     <Button onClick={() => alert("This feature isn't ready yet! Sorry :(")}>
        //         Export
        //     </Button>
        // ]
    }),
    buildCollection({
        relativePath: "jobs",
        schema: jobSchema,
        name: "Jobs"
    }),
    buildCollection({
        relativePath: "banners",
        schema: bannerSchema,
        name: "Banner Ads",
    }),
    buildCollection({
        relativePath: "mosques",
        schema: mosqueSchema,
        name: "Mosques"
    }),
    buildCollection({
        relativePath: "youth-groups",
        schema: youthGroupSchema,
        name: "Youth Groups"
    })
];

const additionalViews: AdditionalView[] = [
    {
        name: "Send Notifications",
        path: "send",
        view: <SendNotifications></SendNotifications>
    }
];

const myAuthenticator: Authenticator = async (user?: User) => {
    var u = await firestore().collection("profiles").where("userId", "==", user?.uid).limit(1).get();
    return !u.empty && u.docs[0].data()["access_cms"];
};

function App() {
    return <CMSApp
        name={"Umoja CMS"}
        logo={logo}
        navigation={navigation}
        allowSkipLogin={false}
        authentication={myAuthenticator}
        firebaseConfig={firebaseConfig}
        additionalViews={additionalViews}
    ></CMSApp>
}

export default App;
