import { EntitySchema } from "@camberi/firecms";

import categories from "../enums/PagesCategories";

const businessSchema: EntitySchema = {
    name: "Business",
    properties: {
        approved: {
            title: "Approved",
            dataType: "boolean",

        },
        name: {
            title: "Name",
            validation: { required: true },
            dataType: "string"
        },
        category: {
            title: "Category",
            validation: { required: true },
            dataType: "string",
            config: {
                enumValues: categories
            }
        },
        description: {
            title: "Description",
            validation: { required: true },
            dataType: "string"
        },
        email: {
            title: "Email",
            validation: { email: true },
            dataType: "string"
        },
        lat: {
            title: "Latitude",
            // validation: { required: true },
            dataType: "number"
        },
        long: {
            title: "Longitude",
            // validation: { required: true },
            dataType: "number"
        },
        logo: {
            title: "Logo",
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "business-directory",
                    acceptedFiles: ["image/*"],
                    storeUrl: true
                }
            }
        },
        phone: {
            title: "Phone",
            dataType: "string",
        },
        website: {
            title: "Website",
            validation: { url: true },
            dataType: "string"
        }
    }
}

export default businessSchema;
