import { EntitySchema } from "@camberi/firecms";
import axios from "axios";
import firebase from "firebase";
import CustomDateTextField from "../components/custom-date";

let profileSchema: EntitySchema = {
    name: "Profile",
    properties: {
        profilePicture: {
            title: "Profile photo",
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "profile-photos",
                    acceptedFiles: ["image/*"],
                    storeUrl: true
                }
            }
        },
        firstName: {
            title: "First name",
            dataType: "string"
        },
        lastName: {
            title: "Last name",
            dataType: "string"
        },
        email: {
            title: "Email",
            dataType: "string",
            validation: {
                email: true
            }
        },
        birthday: {
            title: "Birthday",
            dataType: "string",
            config: {
                field: CustomDateTextField
            }
        },
        emailOptIn: {
            title: "Opt-in to emails?",
            dataType: "boolean",
            disabled: true
        },
        professions: {
            title: "Professions",
            dataType: "array",
            disabled: true,
            of: {
                dataType: "map",
                properties: {
                    field: {
                        dataType: "string"
                    },
                    name: {
                        dataType: "string"
                    }
                }
            }
        },
        subscribedTopics: {
            title: "Topics",
            dataType: "array",
            of: {
                title: "Topic",
                dataType: "string"
            },
        },
        access_cms: {
            title: "Administrator?",
            dataType: "boolean",
            validation: { required: false }
        }
    },
    defaultValues: {
        allowCms: false
    },
    onPreSave: async (x) => {
        console.log("Hi there");
        const id = x.values.id;
        let subscribedTopics = [...x.values.subscribedTopics].filter(y => !!y);
        subscribedTopics = subscribedTopics.map((y: string) => y.trim().toLowerCase());
        console.log("Hey", subscribedTopics);

        const doc = await firebase.firestore().collection("profiles").doc(x.id).get();
        let oldSubscribedTopics: any[] = [];
        if (doc.exists) {
            oldSubscribedTopics = doc.data()!["subscribedTopics"];

            const fcmDoc = firebase.firestore().collection("fcm-tokens").doc(id);
            const fcmDocRef = await fcmDoc.get();
            if (!fcmDocRef.exists) return; // can't add them to topics if they don't have any tokens registered
            const tokens = fcmDocRef.data()!["tokens"];

            const idToken = await firebase.auth().currentUser?.getIdToken();

            // Subscribe to any new topics
            for (const t of subscribedTopics) {
                if (!oldSubscribedTopics.includes(t)) {
                    await axios.post(
                        "https://api.umojaoutreach.org/notifications/subscribe",
                        {
                            "tokens": tokens,
                            "topic": t
                        }, {
                        headers: {
                            "Authorization": "Bearer " + idToken
                        }
                    });
                }
            }

            // Unsubscribe from any old topics
            const unsubscribeTopics = oldSubscribedTopics.filter(x => !subscribedTopics.includes(x));
            for (const t of unsubscribeTopics) {
                await axios.post(
                    "https://api.umojaoutreach.org/notifications/unsubscribe",
                    {
                        "tokens": tokens,
                        "topic": t
                    }, {
                    headers: {
                        "Authorization": "Bearer " + idToken
                    }
                });
            }

            x.values["subscribedTopics"] = subscribedTopics;
            return x.values;
        }
    }
};

export default profileSchema;
