import { EntitySchema } from "@camberi/firecms";
import ctaActionTypes from "../enums/CallToActionTypes";

const bannerSchema: EntitySchema = {
    name: "Banner Ad",
    properties: {
        imageUrl: {
            title: "Banner Image",
            description: "Recommended aspect ratio is 4 to 1. Recommended height at least 500px.",
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "banner-ads",
                    acceptedFiles: ["image/*"],
                    storeUrl: true
                }
            },

        },
        ctaAction: {
            title: "CTA Action",
            dataType: "string",
            description: "Action when the CTA button is pressed. App only.",
            config: {
                enumValues: ctaActionTypes
            }
        },
        ctaUrl: {
            title: "CTA URL",
            dataType: "string",
            description: "If your CTA action says (url), you must set this. Hover the i for info.",
            longDescription: "In most cases, this will be the ID of the resource you're showing. You can retrieve this ID from the CMS - just copy paste the full ID by clicking on the item to expand its info. The only exception is opening a link, which requires the FULL website address."
        },
        ctaMeta: {
            title: "CTA Inspire Meta",
            dataType: "map",
            properties: {
                initialDiscussion: {
                    title: "Initial discussion",
                    dataType: "string",
                    description: "The ID of the discussion to open to.",
                    longDescription: "You can retrieve the discussion ID from the Firebase database. For now, this is not possible in UmojiCMS."
                },
                initialAnswer: {
                    title: "Initial answer",
                    dataType: "string",
                    description: "The ID of the answer to open to. If this is left blank, it'll just navigate to the discussion.",
                    longDescription: "You can retrieve the answer ID from its discussion in the Firebase database. For now, this is not possible in UmojiCMS."
                }
            },
            description: "If you've chosen to show an Inspire post, include this information. Otherwise, ignore it."
        },
        visible: {
            title: "Showing?",
            description: "Should this ad be shown to users?",
            dataType: "boolean",
        }
    },
    // onPreSave: (e) => {
    //     // append a random number so that clients can randomly select a banner ad
    //     const vals = e.values;
    //     const rand = Number.MIN_SAFE_INTEGER + Math.random() * (Number.MAX_SAFE_INTEGER - Number.MIN_SAFE_INTEGER);
    //     vals["rand"] = rand;
    //     return vals;
    // },
    defaultValues: {
        visible: true
    },
};

export default bannerSchema;
