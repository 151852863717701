import { EntitySchema } from "@camberi/firecms";

let videoSchema: EntitySchema = {
    name: "Video",
    properties: {
        title: {
            title: "Title",
            validation: { required: true },
            dataType: "string"
        },
        subtitle: {
            title: "Subtitle",
            validation: { required: true },
            dataType: "string"
        },
        thumbnail: {
            title: "Thumbnail",
            validation: { required: true },
            dataType: "string",
            config: {
                storageMeta: {
                    storagePath: "video-thumbnails",
                    mediaType: "image",
                    acceptedFiles: ["image/*"],
                    storeUrl: true
                }
            },
        },
        url: {
            title: "YouTube Embed URL",
            validation: { required: true, url: true },
            dataType: "string"
        }
    }
}

export default videoSchema;
