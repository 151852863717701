import { EnumValues } from "@camberi/firecms";

const pagesCategories: EnumValues<string> = {
    "Agriculture": "Agriculture",
    "Clothing/Shopping": "Clothing/Shopping",
    "Auto Services": "Auto Services",
    "Restaurant": "Restaurant",
    "Construction": "Construction",
    "Education": "Education",
    "Media": "Media",
    "Community": "Community",
    "Finance": "Finance",
    "Health": "Health",
    "Business and Professional Services": "Business and Professional Services",
    "Home Services": "Home Services",
    "Industrial Supplies": "Industrial Supplies",
    "Personal Care": "Personal Care",
    "Real-estate": "Real-estate",
    "Sports and Recreation": "Sports and Recreation",
    "Transportation": "Transportation",
    "Travel": "Travel"
};

export default pagesCategories;
