import { EnumValues } from "@camberi/firecms";

const ctaActionTypes: EnumValues<string> = {
    "video": "Show a video (url)",
    "mentor": "Show a Mentor podcast (url)",
    "inspire": "Show an Inspire post (url, meta)",
    "link": "Open a web link (url)",
    "news": "Show an announcement (url)",
    "home": "Open the homepage",
    "academy": "Open the Academy page",
    "mosques": "Open the Mosques page",
    "business-directory": "Open the Umoja Pages",
    "professional-directory": "Open uLinks",
    "profile": "Open the user's profile",
    "inbox": "Open the user's inbox",
};

export default ctaActionTypes;
