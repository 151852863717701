import { EntitySchema } from "@camberi/firecms";
import UserField from "../components/user-field";
import marketplaceCategories from "../enums/MarketplaceCategories";

const marketplaceSchema: EntitySchema = {
    name: "marketplace item",
    properties: {
        name: {
            title: "Name",
            dataType: "string",
            validation: { required: true }
        },
        category: {
            title: "Category",
            dataType: "string",
            config: {
                enumValues: marketplaceCategories
            },
            validation: { required: true },
        },
        description: {
            title: "Description",
            dataType: "string",
            config: { multiline: true },
            validation: { required: true }
        },
        price: {
            title: "Price",
            dataType: "number",
            validation: { positive: true }
            // TODO money type field?
        },
        imageUrl: {
            title: "Image",
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "marketplace-items",
                    acceptedFiles: ["image/*"],
                    storeUrl: true
                }
            }
        },
        seller: {
            title: "Seller info",
            dataType: "map",
            properties: {
                userId: {
                    title: "Seller's user ID",
                    dataType: "string",
                    config: {
                        // field: UserField
                    }
                },
                link: {
                    title: "Link",
                    dataType: "string",
                    validation: { url: true }
                },
                phone: {
                    title: "Phone number",
                    dataType: "string"
                }
            }
        }

    },
    defaultValues: {
        price: 0
    },
    onPreSave: (e) => {
        const vals = e.values;
        vals["createdAt"] = formatDate(new Date());
        vals["updatedAt"] = vals["createdAt"];
        vals["seller"]["anonymous"] = true;
        return vals;
    }
};

function formatDate(date: Date): string {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export default marketplaceSchema;
