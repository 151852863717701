import { EntitySchema } from "@camberi/firecms";
import { postWordpress, updateWordpressPost, deleteWordpressPost } from "../api-helper"
import ctaActionTypes from "../enums/CallToActionTypes";

const announcementSchema: EntitySchema = {
    name: "Announcement",
    properties: {
        title: {
            title: "Title",
            validation: { required: true },
            dataType: "string",
        },
        short: {
            title: "Short description",
            validation: { required: true },
            dataType: "string",
            description: "Should be perhaps one sentence."
        },
        when: {
            title: "Date",
            validation: { required: true },
            dataType: "timestamp",
            description: "When did this event occur? This will not affect the time the notification goes out.",
        },
        content: {
            title: "Description",
            validation: { required: true },
            dataType: "string",
            config: {
                multiline: true
            },
            description: "This can be as long as you want, and it can also include HTML."
        },
        image: {
            title: "Featured image",
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "news-thumbnails",
                    acceptedFiles: ["image/*"],
                    storeUrl: true
                }
            }
        },
        height: {
            title: "Image height",
            dataType: "number",
            description: "Optional. The desired height in the image. App only."
        },
        cta: {
            title: "Call to Action (CTA)",
            dataType: "string",
            description: "Text to show on the call-to-action button. App only.",
        },
        ctaAction: {
            title: "CTA Action",
            dataType: "string",
            description: "Action when the CTA button is pressed. App only.",
            config: {
                enumValues: ctaActionTypes
            }
        },
        ctaUrl: {
            title: "CTA URL",
            dataType: "string",
            description: "If your CTA action says (url), you must set this. Hover the i for info.",
            longDescription: "In most cases, this will be the ID of the resource you're showing. You can retrieve this ID from the CMS - just copy paste the full ID by clicking on the item to expand its info. The only exception is opening a link, which requires the FULL website address."
        },
        ctaMeta: {
            title: "CTA Inspire Meta",
            dataType: "map",
            properties: {
                initialDiscussion: {
                    title: "Initial discussion",
                    dataType: "string",
                    description: "The ID of the discussion to open to.",
                    longDescription: "You can retrieve the discussion ID from the Firebase database. For now, this is not possible in UmojiCMS."
                },
                initialAnswer:{ 
                    title: "Initial answer",
                    dataType: "string",
                    description: "The ID of the answer to open to. If this is left blank, it'll just navigate to the discussion.",
                    longDescription: "You can retrieve the answer ID from its discussion in the Firebase database. For now, this is not possible in UmojiCMS."
                }
            },
            description: "If you've chosen to show an Inspire post, include this information. Otherwise, ignore it."
        },
        postWordpress: {
            title: "Create a post on the website.",
            dataType: "boolean",
            description: "If this is checked, the system will automatically create the same announcement post on the website."
        },
        sendNotification: {
            title: "Send a notification on the app immediately.",
            dataType: "boolean",
        }
    },
    onPreSave: async (x) => {
        const vals = x.values;

        if(vals.wpId) {
            await updateWordpressPost(vals.wpId, x.values.title, x.values.short, x.values.content);
        } else if(vals.postWordpress) {
            const wpId = await postWordpress(x.values.title, x.values.short, x.values.content);
            vals.wpId = wpId;
            // vals.postWordpress = undefined; no undefined interpreter yet, needs to be added TODO
        }

        if(vals.sendNotification) {
            // TODO
        }

        return vals;
    },
    onDelete: (e) => {
        if(e.entity.values.wpId) {
            // is a wordpress post, must be deleted
            deleteWordpressPost(e.entity.values.wpId);
        }
    }
};

export default announcementSchema;
