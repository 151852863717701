import axios from "axios";

// TODO this is not safe, we should not store passwords like this,
// but at this time with only a few internal users, it doesn't
// seem like a big deal.
const auth = {
    username: "developers@umojaoutreach.org",
    password: "^ALIMmSvArp957)$Gc7clY&)",
};

/**
 * Creates a post on the Wordpress website using the given parameters.
 */
export async function postWordpress(title: string, short: string, content: string, image?: string) {
    console.log("Dispatching request to Wordpress...");
    const res = await axios.post(
        "https://umojaoutreach.org/wp-json/wp/v2/posts",
        {
            status: "publish",
            type: "post",
            title: title,
            excerpt: short,
            content: content,
            // "image": image,
            author: 11,
            categories: [89, 74],
        },
        { auth }
    );

    return res.data.id;
}

export async function updateWordpressPost(id: string, title: string, short: string, content: string, image?: string) {
    console.log("Dispatching update request to Wordpress...");
    await axios.post(
        "https://umojaoutreach.org/wp-json/wp/v2/posts/" + id,
        {
            title: title,
            excerpt: short,
            content: content,
        },
        { auth }
    );
}

export async function deleteWordpressPost(id: string) {
    console.log("Dispatching deletion to Wordpress...");
    await axios.delete("https://umojaoutreach.org/wp-json/wp/v2/posts/" + id, { auth });
}
