import { EntitySchema } from "@camberi/firecms";

const showSchema: EntitySchema = {
    name: "Mentor Podcast",

    properties: {
        title: {
            title: "Title",
            validation: { required: true },
            dataType: "string",
        },
        subtitle: {
            title: "Subtitle",
            validation: { required: true },
            dataType: "string"
        },
        date: {
            title: "Date",
            validation: { required: true },
            dataType: "timestamp"
        },
        youtubeId: {
            title: "YouTube Video ID",
            description: "For example, for youtube.com/watch?v=xyz, you would input xyz.",
            dataType: "string"
        },
        mp3: {
            title: "MP3 (URL)",
            validation: { required: true, url: true },
            dataType: "string"
        },
        thumbnail: {
            title: "Thumbnail",
            validation: { required: true },
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "mentor-thumbnails",
                    acceptedFiles: ["image/*"],
                    storeUrl: true
                }
            }
        },
        bio: {
            title: "Biography",
            validation: { required: true },
            dataType: "string",
            // customField: CustomLargeTextField,
            config: {
                multiline: true
            }
        }
    }
}

export default showSchema;
