import { EnumValues } from "@camberi/firecms";

/*
  "Appliances",
  "Art",
  "Baby items",
  "Bikes",
  "Books",
  "Clothing",
  "Electronics",
  "Free stuff",
  "Home",
  "Food",
  "Jewelry",
  "Tools",
  "Sporting goods",
  "Toys",
  "Games and consoles",
  */

const marketplaceCategories: EnumValues<string> = {
    "Appliances": "Appliances",
    "Art": "Art",
    "Baby items": "Baby items",
    "Bikes": "Bikes",
    "Books": "Books",
    "Clothing": "Clothing",
    "Electronics": "Free stuff",
    "Home": "Home",
    "Food": "Food",
    "Jewelry": "Jewelry",
    "Tools": "Tools",
    "Sporting goods": "Sporting goods",
    "Toys": "Toys",
    "Games and consoles": "Games and consoles",
};

export default marketplaceCategories;
