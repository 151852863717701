import { Box, Button, Card, InputLabel, MenuItem, Select, Snackbar, TextField } from "@material-ui/core";
import axios from "axios";
import firebase from "firebase";
import React, { useEffect, useState } from "react";

const SendNotifications = () => {
    const [topic, setTopic] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [action, setAction] = useState<any>("");
    const [category, setCategory] = useState<any>("");
    const [uriType, setUriType] = useState("");
    const [uri, setUri] = useState("");
    const [loading, setLoading] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        if (action === "link") setUriType("URL (with http://)");
        else if (action === "news") setUriType("News Post ID");
        else if (action === "mentor") setUriType("Podcast ID");
        else if (action === "video") setUriType("Video ID");
    }, [action]);

    function valid() {
        return true;
    }

    async function onClick() {
        setLoading(true);
        const idToken = await firebase.auth().currentUser?.getIdToken();
        let payload: any = {
            "topic": topic,
            "notificationTitle": title,
            "notificationText": content,
            "categories": [category]
        };
        if (action !== "none") {
            payload["notificationData"] = {
                "action": action,
                "url": uri
            }
        }
        await axios.post(
            "https://api.umojaoutreach.org/notifications/",
            payload, {
            headers: {
                "Authorization": "Bearer " + idToken
            }
        });
        setLoading(false);
        setShowSnackbar(true);
    }

    return (
        <Box style={{ padding: "16px" }}>
            <h1>Send a Notification</h1>
            <Card style={{ padding: "16px" }}>
                <InputLabel style={{ marginTop: 16, marginBottom: 8 }}>Topic</InputLabel>
                <TextField style={{ width: "100%" }} title="Topic" placeholder="Topic" variant="outlined" value={topic} onChange={(e) => setTopic(e.target.value)}></TextField>
                <InputLabel style={{ marginTop: 16, marginBottom: 8 }}>Title</InputLabel>
                <TextField style={{ width: "100%" }} title="Title" placeholder="Title" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)}></TextField>
                <InputLabel style={{ marginTop: 16, marginBottom: 8 }}>Content</InputLabel>
                <TextField style={{ width: "100%" }} title="Content" placeholder="Content" multiline variant="outlined" value={content} onChange={(e) => setContent(e.target.value)}></TextField>
                <InputLabel style={{ marginTop: 16, marginBottom: 8 }}>Category</InputLabel>
                <Select style={{ width: "100%" }} title="Category" placeholder="Category" onChange={(e) => setCategory(e.target.value)}>
                    <MenuItem value="news">News</MenuItem>
                    <MenuItem value="mentor">uMentor</MenuItem>
                    <MenuItem value="academy">uAcademy</MenuItem>
                    <MenuItem value="brains">uBrains</MenuItem>
                    <MenuItem value="care">uCare</MenuItem>
                    <MenuItem value="games">uGames</MenuItem>
                </Select>
                <InputLabel style={{ marginTop: 16, marginBottom: 8 }}>Action</InputLabel>
                <Select style={{ width: "100%" }} title="Action" placeholder="Action" onChange={(e) => setAction(e.target.value)}>
                    <MenuItem key="none" value="none">None</MenuItem>
                    <MenuItem key="link" value="link">External Link</MenuItem>
                    <MenuItem key="news" value="news">News</MenuItem>
                    <MenuItem key="mentor" value="mentor">Podcast</MenuItem>
                    <MenuItem key="video" value="video">Video</MenuItem>
                </Select>
                {action && action !=="none" && <InputLabel style={{ marginTop: 16, marginBottom: 8 }}>{uriType}</InputLabel>}
                {action && action !=="none" && <TextField style={{ width: "100%" }} title={uriType} placeholder={uriType} multiline variant="outlined" value={uri} onChange={(e) => setUri(e.target.value)}></TextField>}
                <Button style={{ width: "100%", marginTop: 16 }} color="primary" variant="contained" onClick={onClick} disabled={loading || !valid()}>
                    {loading ? "Sending..." : "Send"}
                </Button>
            </Card>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={() => setShowSnackbar(false)}
                color="success"
                message="Notification sent!"
            />
        </Box>
    );
};

export default SendNotifications;
