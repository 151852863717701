import { EntitySchema } from "@camberi/firecms";
import CustomDateTextField from "../components/custom-date";

const jobSchema: EntitySchema = {
    name: "Job",
    properties: {
        approved: {
            title: "Approved?",
            dataType: "boolean"
        },
        title: {
            title: "Title",
            dataType: "string",
            validation: { required: true },
        },
        description: {
            title: "Description",
            dataType: "string",
            config: {
                multiline: true
            }
        },
        websiteUrl: {
            title: "More Information URL",
            dataType: "string",
            validation: {
                url: true,
                required: false
            }
        },
        startDate: {
            title: "Start date",
            dataType: "string",
            validation: { required: true },
            config: {
                field: CustomDateTextField
            }
        },
        endDate: {
            title: "End date",
            dataType: "string",
            validation: { required: false },
            config: {
                field: CustomDateTextField
            }
        },
        keywords: {
            title: "Keywords",
            dataType: "array",
            of: {
                dataType: "string"
            }
        },
        companyName: {
            title: "Company name",
            dataType: "string",
            validation: { required: true }
        },
        companyAbout: {
            title: "Company about",
            dataType: "string",
            validation: { required: true },
            config: {
                multiline: true
            }
        },
        companyLogo: {
            title: "Company logo",
            dataType: "string",
            validation: { required: false },
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "job-business-logos",
                    acceptedFiles: ["image/*"],
                    storeUrl: true
                }
            }
        },
        volunteer: {
            title: "Volunteer role?",
            dataType: "boolean"
        },
        location: {
            title: "Location",
            dataType: "map",
            properties: {
                name: {
                    title: "Name",
                    dataType: "string"
                },
                addressText: {
                    title: "Address text",
                    dataType: "string"
                },
                placeId: {
                    title: "Place ID (from Google Maps)",
                    dataType: "string"
                },
                latitude: {
                    title: "Latitude (from Google Maps)",
                    dataType: "number"
                },
                longitude: {
                    title: "Longitude (from Google Maps)",
                    dataType: "number"
                }
            }
        }
    },
    defaultValues: {
        volunteer: false
    },
    onPreSave: async (x) => {
        console.log("Foo");
        // need to store the ID with the collection
        let vals = x.values;
        vals["id"] = x.id;
        console.log(vals["id"]);
        return vals;
    }
};

export default jobSchema;
